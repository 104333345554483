import React, { useEffect, useRef, useContext, createContext, useState } from 'react'
import _ from 'lodash'
import cn from 'classnames'
import { GeneralContext } from '@/provider/GeneralProvider'

import useScrollbarOffset from '@/core/hooks/useScrollbarOffset'

import CustomChatbot from '@/organism/CustomChatbot'
import Drawer_ResourceCard from '@/organism/Drawer_ResourceCard'
import Drawer_Settings from '@/organism/Drawer_Settings'
import Drawer from '@/atom/Drawer'

import constants from '@/core/utils/constants'

import close from '@/assets/icons/close.svg'
import minus from '@/assets/icons/minus.svg'
import settingIcon from '@/assets/icons/global-white.svg'
import angleLeft from '@/assets/icons/angle-left-solid.svg'
import avatar from '@/assets/images/ari_minimized.webp'

import * as styles from './styles.module.scss'

const getScrollbarWidth = () => {
  // Create a temporary element
  const outer = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.overflow = 'scroll' // Force scrollbars
  outer.style.width = '50px'
  outer.style.height = '50px'
  document.body.appendChild(outer)

  // Create an inner element and place it in the container
  const inner = document.createElement('div')
  inner.style.width = '100%'
  outer.appendChild(inner)

  // Calculate the scrollbar width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth

  // Remove temporary elements from the DOM
  outer.parentNode.removeChild(outer)

  return scrollbarWidth
}

const DRAWER_MAPPING = (drawerName, props) => {
  switch (drawerName) {
    case constants.drawers.facility:
      return <Drawer_ResourceCard {...props} />
    case constants.drawers.settings:
      return <Drawer_Settings {...props} />
    default:
      return null
  }
}

export default ({ className, initDataId, ...rest }) => {
  const {
    loaded,
    errorMessage,
    zIndex,
    setShowFacility,
    uiDrawer,
    setUiDrawer,
    client,
    language,
    languageList,
    country,
    countryList,
    labels,
    show,
    setShow,
    direction
    //
  } = useContext(GeneralContext)

  const refChatbox = useRef(null)

  // ========== configs
  const {
    theme,
    display_mode = 'button_toggle', // button|float
    position = 'right_bottom', // right_bottom|right_top|left_bottom|left_top
    is_click_outside_close = true
  } = _.get(client, 'client_config', {})
  // ========== configs

  const scrollBarOffset = useScrollbarOffset()

  useEffect(() => {
    if (show && is_click_outside_close) {
      const handleClickOutside = event => {
        if (initDataId && event.target.id === initDataId) return

        // if click outside of chatbox
        if (refChatbox.current && !refChatbox.current.contains(event.target)) {
          setShow(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)

      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [show, setShow, is_click_outside_close])

  if (!loaded) return null

  const handleCloseDrawer = () => {
    setShowFacility(null)
    setUiDrawer('')
  }

  const jsxError = errorMessage ? (
    <div className={styles.chatbox}>
      <div className={styles.error}>{errorMessage}</div>
    </div>
  ) : null

  const jsxChatbot = !errorMessage ? (
    <div className={cn(styles.chatbox)}>
      <Drawer>{uiDrawer ? DRAWER_MAPPING(uiDrawer) : null}</Drawer>
      <CustomChatbot />
    </div>
  ) : null

  const languageObj = languageList.find(item => item.language_code === language)
  const jsxSettings = (
    <div className={cn(styles.iconSetting)} onClick={() => setUiDrawer(constants.drawers.settings)}>
      <div className={cn(styles.iconSettingContainer)}>
        <img src={settingIcon} />
      </div>
      <span className={cn(styles.settingText)}>
        {country || 'Country'}<span> | </span>
        {languageObj?.native_name || 'Language'}
      </span>
      {/* <span className={cn(styles.settingText)}>Language & Country</span> */}
    </div>
  )

  const jsxZommers = (
    <div className={cn(styles.zoomerContainer)}>
      <div onClick={() => setShow(false)} className={cn(styles.iconCloseContainer)}>
        <img src={close} />
      </div>
    </div>
  )

  const jsxMain = (
    <div
      className={cn(
        'rivara_main',
        className,
        styles.root,
        styles[position],
        styles[direction],
        show ? styles.show : styles.hide
      )}
      style={{ zIndex, ...theme, ...scrollBarOffset }}
      ref={refChatbox}
    >
      <div className={cn(styles.header)}>
        <div className={cn(styles.headerContainer)}>
          <div style={{ display: 'flex' }}>
            {uiDrawer ? (
              <div className={cn(styles.iconBack)} onClick={handleCloseDrawer}>
                <img src={angleLeft} />
                <span className={cn(styles.backText)}>{labels['lbl_Back'] || 'BACK'}</span>
              </div>
            ) : (
              jsxSettings
            )}
            {/* <button onClick={handleTest}>test</button> */}
          </div>
          <div>{!uiDrawer && jsxZommers}</div>
        </div>
      </div>
      <div className={cn(styles.chatboxContainer)}>
        {jsxError}
        {jsxChatbot}
      </div>
    </div>
  )

  const clientChatAvatar = client?.client_chat_avatar ? `${client?.client_chat_avatar}?v=${client.updatedAt}` : avatar
  const jsxFloat = (
    <div
      className={cn(
        'rivara_float',
        styles.float,
        styles[direction],
        styles[position],
        show ? styles.hide : styles.show
      )}
      style={{ zIndex, ...theme, ...scrollBarOffset }}
    >
      <div className={cn(styles.floatContainer)} onClick={() => setShow(true)}>
        <div className={cn(styles.floatAvatar)}>
          <img src={clientChatAvatar} />
        </div>
        <div className={cn(styles.assistant_name)}>{labels['lbl_YourAssistant'] || 'Your Assistant'}</div>
      </div>
    </div>
  )

  return (
    <>
      {jsxMain}
      {display_mode === 'float' && jsxFloat}
    </>
  )
}
