import {LINKTYPES} from "./constants"

const extractPhone = mix => {
  if (!mix) return ''
  // let numb = mix.match(/\d/g);
  let numb = mix.match(/\+|\d/g)

  numb = numb?.join('') || ''
  return numb
}

export const validateSteps = (step, k) => {
  const { question, options, action, type, ...rest } = step
  switch (type) {
    case 'SKIP':
    case 'CLEAR':
      if (!action) console.error(`${k}: action is required`)
      break
    case 'LOC_ZIP':
      if (!question) console.error(`${k}: question is required`)
      if (!action) console.error(`${k}: action is required`)
      break;
    case 'RESULTS':
      if (!question) console.error(`${k}: question is required`)
      if (!action) console.error(`${k}: action is required`)
      break
    case 'OPTIONS_ONLY':
      if (!options) console.error(`${k}: options is required`)
      break
    case 'OPTIONS':
    default:
      if (!options) console.error(`${k}: options is required`)
      if (!question) console.error(`${k}: question is required`)
      break
  }
}

export const getLinktype = (link) => {
  const reg = new RegExp("^(?:[a-z]+:)?//", "i");

  if (link?.toLowerCase().lastIndexOf(".pdf") != -1) {
    return LINKTYPES.PDF;
  }

  if (link?.toLowerCase().indexOf("mailto:") === 0) {
    return LINKTYPES.MAIL;
  }

  if (link?.toLowerCase().indexOf("tel:") === 0) {
    return LINKTYPES.PHONE;
  } else if (reg.test(link)) {
    return LINKTYPES.EXTERNAL;
  } else if (link?.indexOf("/") === 0) {
    return LINKTYPES.ABSOLUTE;
  } else {
    return LINKTYPES.RELATIVE;
  }
};

export default {
  extractPhone,
}
