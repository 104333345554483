
import React, { useEffect, useContext } from 'react';
import { GeneralContext } from '@/provider/GeneralProvider'
import _ from 'lodash'


const Hook = (previousStep) => {
  const {
    onStageSetup,
    onStageClear
    //
  } = useContext(GeneralContext)

  useEffect(() => {
    // collect user inputs for search

    let scode = '', scode_method = ''
    let input = {}

    /*
      20240624 - note: 
        prevStepObj is actually current step because it means "Agent" step. which is previous step from system's perspective
        the step before Agent is really the previous step from user's perspective
    */
    // if there is metadata, use id
    const prevStepObj = {...previousStep, ...(previousStep?.metadata || {})}

    if (prevStepObj?.values && typeof prevStepObj?.values === 'object') {
      input = {...input, ...prevStepObj.values}
    }

    // console.log("prevStepObj", prevStepObj)

    if (prevStepObj?.type === 'LOC_ZIP') {
      // "value" is simple chat built-in approach to pass user input
      input['zip'] = prevStepObj.value
    } 
    
    if (prevStepObj?.scode) {
      scode_method = prevStepObj?.scode_method
      scode = prevStepObj?.scode
    }

    // 20240624 - change to: clear when current is ENTRY instead of next step is ENTRY. (consistency because "type:CLEAR" is current too)
    if (prevStepObj.id === 'ENTRY' || prevStepObj.type === 'CLEAR') {
      onStageClear()
    } 
    
    onStageSetup(scode, scode_method, input, prevStepObj?.operation )

    // if there is redirect_url
    if (previousStep?.redirect_url) {
      window.open(previousStep.redirect_url, '_blank')
    } 

  }, []);
}

export default Hook;