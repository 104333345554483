import axios from 'axios'
import LanguageToISOCode, { mappingLang, mappingLangBrowser } from './LanguageToISOCode.js'

let SERVER = 'https://api.ww.mbdtst.com'
let CND_SERVER = 'https://cdn.ww.mbdtst.com'

SERVER = 'https://api.rivara.ai'
CND_SERVER = 'https://cdn.rivara.ai'

if (process.env.BUILD_FOR?.trim() == 'dev' || process.env.BUILD_FOR?.trim() == 'stage') {
  console.log('process.env.BUILD_FOR', process.env.BUILD_FOR)
}

if (process.env.BUILD_FOR?.trim() == 'dev') {
  // SERVER = 'http://localhost:8080'
  SERVER = 'https://api.rivara.ai'
  CND_SERVER = 'https://cdn.ww.mbdtst.com'
}

if (process.env.BUILD_FOR?.trim() == 'stage') {
  SERVER = 'https://api.ww.mbdtst.com'
  CND_SERVER = 'https://cdn.ww.mbdtst.com'
}

if (process.env.BUILD_FOR?.trim() == 'live') {
  SERVER = 'https://api.rivara.ai'
  CND_SERVER = 'https://cdn.rivara.ai'
}

const headers = {}
const request = axios.create({
  baseURL: SERVER + '/wc/heatherai',
  headers
})

const browserLanguage = () => {
  let l_lang
  if (navigator.userLanguage)
    // Explorer
    l_lang = navigator.userLanguage
  else if (navigator.language)
    // FF
    l_lang = navigator.language
  else l_lang = ''

  // some languages has different standard between browser and BCP-47
  l_lang = l_lang.toLowerCase()
  l_lang = mappingLangBrowser[l_lang] || mappingLangBrowser[l_lang.split('-')[0]] || l_lang.split('-')[0]

  if (process.env.BUILD_FOR?.trim() == 'dev' || process.env.BUILD_FOR?.trim() == 'stage') {
    console.log('browser language code:', l_lang)
  }

  return l_lang
}

const getQString = queryObj => {
  if (!queryObj.lang) {
    queryObj.browserLang = browserLanguage()
  }

  const params = []
  for (const key in queryObj) {
    params.push(`${key}=${queryObj[key] || ''}`)
  }
  return params.join('&')
}

export default {
  SERVER,
  CND_SERVER,
  async getToken(access_code) {
    const res = await request.get(`/token?access=${access_code}`)
    const data = res?.data
    return data
  },

  async getSettings(accesstoken, lang, country, { isPreview }) {
    const qString = getQString({ accesstoken, lang, country, isPreview })
    const res = await request.get(`/settings?${qString}`)
    const data = res?.data
    return data
  },

  async getTree(accesstoken, lang, country, { cookieOff, isPreview }) {
    const qString = getQString({ accesstoken, lang, country, cookieOff, isPreview })
    const res = await request.get(`?${qString}`)
    let data = res?.data
    // convert data to json string replace @base_url with CND_SERVER, then parse it back to json object
    if (data) {
      let dataString = JSON.stringify(data)
      dataString = dataString.replace(/@base_url/g, CND_SERVER)
      data = JSON.parse(dataString)

      const language_code = data?.currentLanguage
      if (language_code) {
        localStorage.setItem('rivara_currentLanguage', language_code)
      }

      const country_code = data?.currentCountry
      if (country_code) {
        localStorage.setItem('rivara_currentCountry', country_code)
      }
    }
    if (process.env.BUILD_FOR?.trim() == 'dev' || process.env.BUILD_FOR?.trim() == 'stage') {
      console.log('getTree data', data)
    }

    return data
  },

  async getResults(accesstoken, sCodes, sCodes_or, sCodes_notexists, input, lang, country, { isPreview }) {
    const params = {
      sCodes,
      sCodes_or,
      sCodes_notexists,
      input
    }
    const qString = getQString({ accesstoken, lang, country, isPreview })
    const res = await request.post(`/action/RESULTS?${qString}`, params)
    const data = res?.data
    // test
    return data
  },
  async getFacility(accesstoken, org_id, {isPreview}) {
    const qString = getQString({ accesstoken, isPreview })
    const res = await request.get(`/facility/${org_id}?${qString}`)
    const data = res?.data
    return data
  },
  async recordEngagement(o) {
    const {isPreview} = o
    if (isPreview) {
      console.log('you are in preview mode, skip record engagement', o)
      return null
    }

    const res = await request.post(`/record`, o)
    const data = res?.data
    return data
  },
  async recordVisitor({ visitor_token, accesstoken, isPreview }) {
    if (isPreview) {
      console.log('you are in preview mode, skip record visitor', { visitor_token, accesstoken })
      return null
    }
    const res = await request.post(`/recordVisitor`, { visitor_token, accesstoken })
    const data = res?.data
    return data
  }
}
