// import ReportApi from "@provider/ReportApi";
import _ from 'lodash'
import { validateSteps } from './helper.js'

const regexMapping = {
  US: '^[0-9]{5}(-[0-9]{4})?$'
}

/**
 * Handles the json chatbot data to code functionality
 * @param {object} defaultData
 * @param {object} clientData
 * @returns {object}
 */
export const process = (tree, labels) => {
  const steps = []

  /* 
    loop through the tree and generate the steps. 
    those steps are NOT directly used for react-simple-chatbot.
    we will loop it again, fetch valid variables, then generate the steps for react-simple-chatbot
  */
  _.keys(tree)?.map((k, i) => {
    /* 
      NOTE: the package uses "trigger" for next step while we use "action"
      because its not necessary one-step. 
      when its multiple-step to finish an action. we use trigger for internal redirection
    */
    let { question, options, action, type, ...rest } = tree[k]

    // step1 is the question step
    let step_1 = {}

    // step2 is the input step. can be options, input, etc
    let step_2 = null

    // if we dont assign type, use key name as type
    if (!type) type = 'OPTIONS'

    /* 
      message and option must be seperated. so we need go regenrate the id
      its to break it to 2 steps.
    */
    step_1['id'] = k
    if (question) {
      step_1['custom'] = 'CustomStep_Question'
      // step_1['message'] = question
      step_1['metadata'] = { message: question }
    }

    // metadata of the node (shared data between multiple steps)
    const nodeMetaData = { type }

    // special types start ==================
    // === if there is option. we put it to next message ===
    // --- one step nodes --- (its functional type)
    if (type === 'SKIP' || type === 'CLEAR') {
      validateSteps(tree[k], k)
      step_1['custom'] = 'CustomStep_Skip' // this will be handled in 2nd loop. convert to custom component
      step_1['action'] = action
      step_1['metadata'] = { ...tree[k] }
    }

    if (type === 'HTMLPANEL') {
      step_1['custom'] = 'CustomStep_HtmlPanel'
      step_1['trigger'] = action
      step_1['metadata'] = { ...tree[k] }
    }

    // --- two step nodes --- (its queston-answer type)
    if (type === 'RESULTS') {
      validateSteps(tree[k], k)
      step_2 = {}
      step_2['id'] = `${k}.resource`
      step_1['trigger'] = step_2['id']

      // real functionality
      step_2['custom'] = 'CustomStep_Resource'
      step_2['metadata'] = { action }
      step_2['trigger'] = action
    }

    if (type === 'OPTIONS_ONLY') {
      step_1['options'] = options.map(o => {
        return {
          label: o.option,
          value: o.option,
          trigger: 'chatbot.agent',
          metadata: { value: o.value, action: o.action },
          ...o
        }
      })
    }

    if (type === 'OPTIONS') {
      validateSteps(tree[k], k)
      step_2 = {}
      step_2['id'] = `${k}.optionStep`
      step_1['trigger'] = step_2['id']

      // real functionality
      step_2['options'] = options.map(o => {
        return {
          label: o.option,
          value: o.option,
          trigger: 'chatbot.agent',
          metadata: o,
          ...o
        }
      })
    }

    // special inputs
    if (type === 'LOC_ZIP') {
      step_1['trigger'] = `${k}.optionStep`
      step_2 = {
        id: `${k}.optionStep`,
        user: true,
        validator: value => {
          if (isNaN(value) || value.length !== 5) {
            return labels['lbl_Invalid_ZipCodeUS'] || 'Please enter a valid 5-digit zipcode.'
          }

          // regex for US zip code
          const zipCodeRegex = new RegExp(regexMapping['US'])

          if (!zipCodeRegex.test(value)) {
            return labels['lbl_Invalid_ZipCodeUS'] || 'Please enter a valid 5-digit zipcode.'
          }

          return true
        },
        metadata: { action },
        trigger: 'chatbot.agent'
      }
    }
    // special types end ==================

    // push the steps
    if (step_1) {
      step_1['metadata'] = _.merge(step_1['metadata'], nodeMetaData)
      steps.push(step_1)
    }

    if (step_2) {
      step_2['metadata'] = _.merge(step_2['metadata'], nodeMetaData)
      steps.push(step_2)
    }
  })

  // steps.push({
  //   id: 'end',
  //   // Define the next step based on the user's choice or a specific message
  //   // For example:
  //   message: 'You selected option {previousValue}',
  //   end: true
  // })

  return steps
}

export default {
  process
}
