import React, { useContext, useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import ChatBot from 'react-simple-chatbot'
import { GeneralContext } from '@/provider/GeneralProvider'

import avatar from '@/assets/images/ari_minimized.webp'
import noUserAvatar from '@/assets/images/no-avatar.webp'

import CustomStep_Agent from '@/molecule/CustomStep_Agent'
import CustomStep_Skip from '@/molecule/CustomStep_Skip'
import CustomStep_Resource from '@/molecule/CustomStep_Resource'
import CustomStep_Question from '@/molecule/CustomStep_Question'
import CustomStep_HtmlPanel from '@/molecule/CustomStep_HtmlPanel'

import * as styles from './styles.module.scss'

const bubbleStyle = {
  borderRadius: 12,
  color: '#002677',
  padding: 12,
  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
}

const bubbleOptionStyle = {
  backgroundColor: '#faf8f2',
  border: '1px solid #002677',
  color: '#002677',
  cursor: 'pointer',
  padding: '8px 12px',
  marginBottom: 4,
  fontFamily: 'Optum Sans, Arial, sans-serif'
}

const footerStyle = {
  fontFamily: 'Optum Sans, Arial, sans-serif'
}

export default ({ className, ...rest }) => {
  const {
    chatBotData,
    currentStep,
    client,
    stage,
    labels,
    userAvatar
    //
  } = useContext(GeneralContext)

  const [chatData, setChatData] = useState(null)

  const modalRef = useRef(null)

  useEffect(() => {
    if (modalRef.current) {
      const modalElement = modalRef.current.querySelector('.rsc-content')
      const handleScroll = event => {
        const { scrollTop, scrollHeight, clientHeight } = modalElement
        // prevent scroll up, but allow scroll down
        if (scrollTop === 0 && event.deltaY < 0) event.preventDefault()

        // prevent scroll down, but allow scroll up
        if (scrollTop + clientHeight >= scrollHeight && event.deltaY > 0) event.preventDefault()
      }

      const handleTouchMove = event => {
        if (!event.cancelable) return
        const { scrollTop, scrollHeight, clientHeight } = modalElement
        const touch = event.touches[0]

        // prevent scroll up, but allow scroll down
        if (scrollTop === 0 && touch.clientY > touchStartY) event.preventDefault()

        // prevent scroll down, but allow scroll up
        if (scrollTop + clientHeight >= scrollHeight && touch.clientY < touchStartY) event.preventDefault()
      }

      const handleTouchStart = event => {
        touchStartY = event.touches[0].clientY
      }

      let touchStartY = 0

      // get the modal element inside ref.current, classname is "rsc-content"
      modalElement.addEventListener('wheel', handleScroll)
      modalElement.addEventListener('touchstart', handleTouchStart)
      modalElement.addEventListener('touchmove', handleTouchMove)

      // handle browser deprecated event causes scroll issue
      const observer = patchOnScrollIssue(modalElement)
      return () => {
        modalElement.removeEventListener('wheel', handleScroll)
        modalElement.removeEventListener('touchstart', handleTouchStart)
        modalElement.removeEventListener('touchmove', handleTouchMove)

        // handle browser deprecated event causes scroll issue
        if (observer) {
          observer.disconnect()
        }
      }
    }
  }, [modalRef.current])

  useEffect(() => {
    if (chatBotData) {
      // handle custom steps
      // console.log(chatBotData)
      const newSteps = chatBotData?.map((item, index) => {
        const { custom, action, ...rest } = item
        const _c = {
          ...rest
        }

        if (custom) {
          switch (custom) {
            case 'CustomStep_Skip':
              _c.asMessage = false
              _c.replace = true
              _c.component = <CustomStep_Skip {...item} />
              break
            case 'CustomStep_Resource':
              _c.waitAction = true
              _c.component = <CustomStep_Resource />
              break
            case 'CustomStep_Question':
              _c.asMessage = true
              _c.waitAction = false
              _c.component = <CustomStep_Question {...item} />
              break
            case 'CustomStep_HtmlPanel':
              _c.waitAction = false
              _c.component = <CustomStep_HtmlPanel {...item} />
              break
            default:
              break
          }
        }

        return _c
      })

      // Agent and Resource are fixed custom steps
      newSteps.push({
        id: 'chatbot.agent',
        component: <CustomStep_Agent />,
        asMessage: false,
        replace: true,
        waitAction: true
      })

      setChatData(newSteps)
    }
  }, [chatBotData])

  if (chatData === null) return null
  if (process.env.BUILD_FOR?.trim() == 'dev' || process.env.BUILD_FOR?.trim() == 'stage') {
    console.log(stage)
  }
  // in the future it will be a list
  const isShowFooter = currentStep?.metadata?.type === 'LOC_ZIP'

  const clientChatAvatar = client?.client_chat_avatar ? `${client?.client_chat_avatar}?v=${client.updatedAt}` : avatar

  return (
    <div ref={modalRef} style={{ height: '100%', overflowY: 'auto', padding: '1px 0px', boxSizing: 'border-box' }}>
      <ChatBot
        // handleEnd={e => handleEnd(e)}
        hideHeader={true}
        userAvatar={userAvatar || noUserAvatar}
        botAvatar={clientChatAvatar}
        // hideBotAvatar={false}
        // hideUserAvatar={true}
        enableSmoothScroll={true}
        userDelay={0}
        botDelay={0}
        avatarStyle={{ boxShadow: 'none', padding: 0 }}
        bubbleStyle={bubbleStyle}
        bubbleOptionStyle={bubbleOptionStyle}
        customStyle={{ backgroundColor: 'transparent', boxShadow: 'none', padding: 0, border: 'none' }}
        footerStyle={{ ...footerStyle, display: isShowFooter ? 'block' : 'none' }}
        inputStyle={{}}
        steps={chatData}
        className={cn(styles.chatbot)}
        placeholder={labels['lbl_TypeTheMessage'] || 'Type the message'}
        // customDelay = {10000000}
      />
    </div>
  )
}

const patchOnScrollIssue = (modalElement) => {
  // Callback function to handle mutations
  const mutationCallback = mutationsList => {

    for (let mutation of mutationsList) {
      if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
        modalElement.scrollTop = modalElement.scrollHeight
      }
    }
  }

  // Create a MutationObserver instance and pass the callback function
  const observer = new MutationObserver(mutationCallback)
  if (modalElement) {
    observer.observe(modalElement, { childList: true, subtree: true, characterData: true })
  }

  // Cleanup function to disconnect the observer when the component unmounts
  return observer
}
