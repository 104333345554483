import React, { createContext, useState, useEffect } from 'react'
import _, { set } from 'lodash'
import Api from './Api'
import constants from '@/core/utils/constants'
import helper from '@/core/utils/helper'
import { process } from '@/core/utils/ChatData'

export const GeneralContext = createContext(null)

// we leave it empty so that server can detect from IP. real default county set from server because for country we need IP detection
const DEFAULT_COUNTRY = '' 
const DEFAULT_LANGUAGE = 'en-US'

const cookieLang = localStorage.getItem('rivara_currentLanguage') || ''
const cookieCountry = localStorage.getItem('rivara_currentCountry') || ''

/* 
  NOTE: react web component doesnt allow "undefined" as useEffect dependency 
  so we need to set default value for all props
  otherwise, it will crush without any error message
*/
export const GeneralProvider = ({
  className,
  initAccessCode = '',
  initLanguage = '',
  initCountry = '',
  initUserAvatar = '',
  initZIndex = '',
  initCookieOff = false,
  initIsPreview = false,
  children,
  // ==== custom updates from outside ====
  customLanguage = '',
  customCountry = '',
  customAccessCode = '',
  customUserAvatar = '',
  customCookieOff = false,
  triggerer = '',
  show,
  setShow
}) => {
  // passed in
  const [language, setLanguage] = useState(initLanguage || cookieLang || DEFAULT_LANGUAGE)
  const [country, setCountry] = useState(initCountry || cookieCountry || DEFAULT_COUNTRY)
  const [accessCode, setAccessCode] = useState(initAccessCode)
  const [userAvatar, setUserAvatar] = useState(initUserAvatar)
  const [zIndex, setZIndex] = useState(initZIndex)
  const [cookieOff, setCookieOff] = useState(initCookieOff)
  const isPreview = initIsPreview === 'true' || initIsPreview === true || initIsPreview === '1' || initIsPreview === 1

  // for first load. then we clamp it to the context
  const [labels, setLabels] = useState({})
  const [loaded, setLoaded] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [chatBotData, setChatBotData] = useState(null)
  const [currentStep, setCurrentStep] = useState('')
  const [token, setToken] = useState(null)
  const [client, setClient] = useState(null)

  const [languageList, setLanguageList] = useState([])
  const [countryList, setCountryList] = useState([])
  const [visitorId, setVisitorId] = useState('')

  const [direction, setDirection] = useState('ltr')

  const [showFacility, setShowFacility] = useState(
    null
    // {org_id: 20369}
  )

  const [uiDrawer, setUiDrawer] = useState('')

  // only used once. to record visitor when first open the chatbox
  const [hasRecordedVisitor, setHasRecordedVisitor] = useState(false)

  const zIndexInt = parseInt(zIndex) || 1000000

  // to store halting parameters
  // { step,  sCodes, input, cue }
  const [stage, setStage] = useState()

  useEffect(() => {
    if (accessCode) {
      // validate access code
      initClient(accessCode)
    }
  }, [accessCode])

  useEffect(() => {
    if (token && language && country) {
      // read tree and translations
      initRivara(token, language, country)
    }
  }, [token, language, country])

  useEffect(() => {
    if (token && show && !hasRecordedVisitor) {
      // only used once. to record visitor when first open the chatbox
      initVisitor(token)
    }
  }, [token, show, hasRecordedVisitor])

  // ============= custom updates from outside =============
  useEffect(() => {
    if (customAccessCode || customLanguage) {
      setAccessCode(customAccessCode)
      setLanguage(customLanguage)
      setCountry(customCountry)
    }
  }, [customAccessCode, customLanguage, customCountry, triggerer])

  useEffect(() => {
    if (customUserAvatar) {
      setUserAvatar(customUserAvatar)
    }
  }, [customUserAvatar, triggerer])

  useEffect(() => {
    if (customCookieOff) {
      setCookieOff(customCookieOff)
    }
  }, [customCookieOff, triggerer])
  // ============= custom updates from outside =============

  const onSwitchLanguage = async lang => {
    setLanguage(lang)
    setUiDrawer('')
  }

  const onSwitchCountry = async country => {
    setCountry(country)
    setUiDrawer('')
  }

  const onStageSetup = (scode, scode_method = 'ATTACH', input = {}, operation = 'AND') => {
    // currently only USER will input

    // add parameters
    setStage(prev => {
      const _v = _.cloneDeep(prev) || {}

      let bucket = 'sCodes'
      if (operation === 'AND') {
        bucket = 'sCodes'
      } else if (operation === 'OR') {
        bucket = 'sCodes_or'
      } else if (operation === 'NOTEXISTS') {
        bucket = 'sCodes_notexists'
      }

      let currentSCodes = _v[bucket] || []
      switch (scode_method) {
        case 'REPLACE':
          currentSCodes = typeof scode === 'string' ? [scode] : scode
          break
        case 'ATTACH':
        default:
          // for scode: add scode to the string array, if not already there
          if (scode && !currentSCodes.includes(scode)) {
            currentSCodes.push(scode)
          }
          break
      }

      _v[bucket] = currentSCodes

      // for input: if new value is not empty, replace it, otherwise delete old value
      _v.input = { ..._v.input, ...input }
      Object.keys(_v.input).forEach(k => {
        // if input is empty, delete it. if input is undefined, don't touch it
        if (input[k] !== undefined && !input[k]) {
          delete _v.input[k]
        }
      })

      return _v
    })
  }

  const onStageClear = () => {
    setStage(null)
  }

  const updateCurrentStep = previousStep => {
    const step = chatBotData.find(item => item.id === previousStep?.action)
    setCurrentStep(step)
  }

  async function initClient(accessCode) {
    setErrorMessage(null)
    setLoaded(false)
    try {
      // init client by token
      const res = await Api.getToken(accessCode)
      if (res?.invalid) {
        throw new Error(res?.messages)
      }
      
      setToken(res.token)
      setClient(res.client)

      // init settings: language list, country list, detected country if empty
      const {languageList, countryList, ipCountry} = await Api.getSettings(res.token, cookieLang, cookieCountry, {isPreview})
      setLanguageList(languageList || [])
      setCountryList(countryList || [])
      if(ipCountry) {
        setCountry(ipCountry)
      }
    } catch (err) {
      setErrorMessage(err?.message)
    } finally {
      setLoaded(true)
    }
  }

  async function initRivara(token, lang, country) {
 
    setErrorMessage(null)
    setLoaded(false)
    try {
      // get the tree
      const data = await Api.getTree(token, lang, country, {cookieOff, isPreview})
      if (!data?.logicTree) {
        throw new Error(
          labels['lbl_Error_LostConnection'] || 'Oops, we lost connection to the server. Please try again later.'
        )
      }

      setLabels(data?.labels)
      const botProcessedData = process(data?.logicTree, data?.labels)
      setChatBotData(botProcessedData)

      setDirection(data?.direction || 'ltr')
      onStageClear()
    } catch (err) {
      setErrorMessage(err?.message)
    } finally {
      setLoaded(true)
    }
  }

  async function initVisitor(token) {
    try {
      // get visitor_token
      let visitor_token = localStorage.getItem('visitor_token') || ''

      // if we dont turn off cookie, and we dont have token. means new visitor
      if (!cookieOff && !visitor_token) {
        visitor_token = 'new'
      }

      const visitor = await Api.recordVisitor({ visitor_token, accesstoken: token, isPreview })

      // store visitor
      if (visitor?.visitor_token && !cookieOff) {
        localStorage.setItem('visitor_token', visitor?.visitor_token)
      }

      if (visitor?.visitor_id) {
        setVisitorId(visitor?.visitor_id)
      }

      setHasRecordedVisitor(true)
    } catch (err) {
      console.error('recordVisitor', err)
    } finally {
    }
  }

  const contextData = {
    // init data
    chatBotData,
    errorMessage,
    setErrorMessage,
    labels,
    languageList,
    countryList,
    language,
    country,
    userAvatar,
    currentStep,
    showFacility,
    setShowFacility,
    direction,
    uiDrawer,
    setUiDrawer,
    show,
    setShow,
    isPreview,
    // stage management
    stage,
    setStage,
    onStageSetup,
    onStageClear,
    onSwitchLanguage,
    onSwitchCountry,
    updateCurrentStep,
    zIndex: zIndexInt,
    client,
    token,
    visitorId,
    loaded
  }

  if (!accessCode) {
    return <div style={{ display: 'none' }}>Rivara: no access code</div>
  }
  return <GeneralContext.Provider value={{ ...contextData }}>{children}</GeneralContext.Provider>
}
