import React, { useEffect , useContext} from 'react';
import useUserInput from '@/core/hooks/useUserInput'
import { GeneralContext } from '@/provider/GeneralProvider'

const Com = ({ triggerNextStep, previousStep, action, ...rest }) => {
  const {
    updateCurrentStep,
    //
  } = useContext(GeneralContext)


  // collect user inputs for search
  useUserInput({...previousStep, ...rest})

  useEffect(() => {
    // Trigger the next step
    updateCurrentStep(previousStep)
    triggerNextStep({ trigger: action });
  }, []);

  // Return null since we don't want to render anything for this step
  return null;
};

export default Com;