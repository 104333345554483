import React, { useEffect, useContext, useState } from 'react'
import cn from 'classnames'

import iconPhone from '@/assets/icons/phone-solid-white.svg'
import iconGlobal from '@/assets/icons/global-white.svg'
import iconMessage from '@/assets/icons/message-bubble-white.svg'

import Api from '@/provider/Api'
import utils from '@/core/utils/helper'
import { GeneralContext } from '@/provider/GeneralProvider'
import * as styles from './styles.module.scss'

const HtmlContent = ({metadata, triggerNextStep, previousStep }) => {
  const {
    updateCurrentStep,
    //
  } = useContext(GeneralContext)

  useEffect(() => {
    // Trigger the next step
    updateCurrentStep(previousStep)
    // triggerNextStep()
  }, [triggerNextStep])

  return (
    <div className={styles.root} dangerouslySetInnerHTML={{ __html: metadata?.html }} />
  );
};

export default HtmlContent
