import React, { useEffect, useContext, useState } from 'react'


const HtmlContent = ({ metadata }) => {
  return (
    <div dangerouslySetInnerHTML={{ __html: metadata?.message }} />
  );
};

export default HtmlContent
