@use "source/webComponent/theme" as *;

.show {
  display: flex;
}
.hide {
  display: none;
}

.error {
  color: red;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

// =========== position start ============
.root.right_bottom {
  @include breakpoint-up(md) {
    right: $offset_chat_x;
    bottom: $offset_chat_y;
  }
}
.root.left_bottom {
  @include breakpoint-up(md) {
    left: $offset_chat_x;
    bottom: $offset_chat_y;
  }
}
.root.right_top {
  @include breakpoint-up(md) {
    right: $offset_chat_x;
    top: $offset_chat_y;
  }
}
.root.left_top {
  @include breakpoint-up(md) {
    left: $offset_chat_x;
    top: $offset_chat_y;
  }
}
.float.right_bottom {
  right: $offset_x;
  bottom: $offset_y;
}
.float.left_bottom {
  left: $offset_x;
  bottom: $offset_y;
}
.float.right_top {
  right: $offset_x;
  top: $offset_y;
}
.float.left_top {
  left: $offset_x;
  top: $offset_y;
}

// =========== position end ============

.float, .root {
  font-size: $fontBase;
}

.float {
  position: fixed;
  right: 20px;
  bottom: 20px;
  font-family: $fontFamily;
  .floatContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    cursor: pointer;
    .floatAvatar {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      // center shadow effect
      box-shadow: 0 0 5px 0px $color_black;
      background-color: $color_avatar_background;
      transition: all 0.2s;
      img {
        width: 90%;
        height: 90%;
        border-radius: 50%;
        object-fit: contain;
      }
    }
    .assistant_name {
      font-size: 14px;
      color: $color_textLight;
      text-align: center;
      background-color: $color_primary;
      color: $color_background;
      padding: 5px $spacine_px * 2;
      border-radius: $spacing_borderradius;
      margin-top: -12px;
      border: 1px solid $color_border;
      transition: all 0.2s;
    }
    &:hover {
      .floatAvatar {
        transition: all 0.2s;
        box-shadow: 0 0 10px 0px $color_black;
      }
      .assistant_name {
        transition: all 0.2s;
        background-color: $color_primary_hover;
      }
    }
  }
}

.root {
  // =========== version 1: side ============
  // // use mixin breakpoint-up
  // @include breakpoint-up(xl) {
  //   width: 50dvw;
  // }

  // @include breakpoint-up(lg) {
  //   width: 60dvw;
  // }

  // @include breakpoint-up(md) {
  //   width: 80dvw;
  // }
  // height: 100dvh;
  // top: 0px;
  // left: 0px;
  // position: fixed;

  // =========== version 2:  center ============

  // center of the screen

  // transform: translate(-50%, -50%) translateZ(0);
  position: fixed;
  height: 100dvh;
  width: 100%;
  right: 0px;
  bottom: 0px;
  box-sizing: border-box;
  background-color: #ffffff88;
  overflow: hidden;
  font-family: $fontFamily;
  flex-direction: column;
  backdrop-filter: blur(3px);

  @include breakpoint-up(md) {
    height: 90dvh;
    min-width: 360px;
    max-width: 500px;
    border: 1px solid $color_border;
    border-radius: $spacing_borderradius;
  }

  .header {
    height: 90px;
    background-color: $color_primary;
    .headerContainer {
      height: 60px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .zoomerContainer {
        display: flex;
        .iconCloseContainer {
          // width: calc(10dvh - 20px);
          height: 60px;
          cursor: pointer;
          img {
            height: 40px;
            width: 40px;
            margin: 10px;
            transition: transform 0.2s;
          }

          &:hover {
            img {
              transform: scale(1.1);
              transition: transform 0.2s;
            }
          }
        }
      }

      .iconSetting {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        opacity: 0.6;

        .iconSettingContainer {
          height: 60px;
          padding-left: 15px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          img {
            height: 16px;
            width: 16px;
            margin: 10px;
            transition: transform 0.2s;
          }
        }
        .settingText {
          font-size: 14px;
          color: $warmWhite;
          transition: all 0.2s;
        }

        &:hover {
          opacity: 1;
          img {
            transform: scale(1.1);
            transition: transform 0.2s;
          }

          .settingText {
            transition: all 0.2s;
          }
        }
      }

      .iconBack {
        height: 60px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $spacine_px;
        font-size: 20px;
        img {
          height: 40px;
          width: 40px;
          margin: 10px;
          transition: transform 0.2s;
        }

        &:hover {
          img {
            opacity: 0.8;
            transition: all 0.2s;
          }
          .backText {
            opacity: 0.8;
            transition: all 0.2s;
          }
        }

        .backText {
          font-weight: 600;
          color: $warmWhite;
          transition: all 0.2s;
        }
      }
    }
  }
  .chatboxContainer {
    margin-top: -40px;
    padding: $spacine_px;
    flex-grow: 1;
    height: calc(100% - 90px);
  }
  .chatbox {
    background-color: $color_background;
    border-radius: $spacing_borderradius;
    border: 1px solid $color_border;
    // shadow
    // box-shadow: 0 0px 15px 0px $color_border;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden; // for drawer
    display: flex;
    flex-direction: column;
    position: relative;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
}


.RTL {
  text-align: right;
  direction: rtl;
}
.LTR {
  text-align: left;
  direction: ltr;
}