@use "source/webComponent/theme" as *;
.chatbot {
  width: 100%;
  height: 100%;
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: $spacing_borderradius;
  }

  @include breakpoint-up(xl) {
    ::-webkit-scrollbar {
      width: 8px;
    }
  }

  :global {
    .rsc-container {
      background-color: white;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .rsc-content {
      margin-top: 0;
      // height: 87dvh;
      //  height: 100%;
      display: flex;
      flex-flow: column nowrap;
      overflow-y: auto;
      color-scheme: light;
      // border: 1px solid $color_border;
      // padding: $spacine_px;
      box-sizing: border-box;
      flex-grow: 1;

      @include breakpoint-up(xl) {
        // height: 90dvh;
        // height: 100%;
      }
      // ============== scroll bar styles ==============
      // webkit browsers
      &::-webkit-scrollbar {
        width: 4px; /* Width of the scrollbar */
      }
      &::-webkit-scrollbar-thumb {
        // background: $color_primary; /* Color of the scrollbar thumb */
        border-radius: 4px; /* Roundness of the scrollbar thumb */
      }
      &::-webkit-scrollbar-thumb:hover {
        // background: $color_background; /* Color of the scrollbar thumb on hover */
      }

      // &::-webkit-scrollbar-track {
      //   background: $color_background; /* Color of the scrollbar track */
      // }
    }

    .rsc-ts-bot {
      align-items: flex-start;
      margin-bottom: 30px;
      padding: 0 24px;
      line-height: 20px;
      &:first-child {
        margin-top: auto !important;
        padding-top: 24px;
      }
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 24px;
      }
    }

    .rsc-ts-bubble {
      background-color: $warmWhite;
      font-family: $fontFamily;
      margin: 0;
      max-width: 380px;
    }

    .rsc-footer {
      background-color: $color_gray;
    }

    .rsc-input {
      padding-left: 35px;
      // border-bottom: 1px solid $color_border;
      // border-left: 1px solid $color_border;
      // border-right: 1px solid $color_border;
    }

    // .rsc-ts-image-container {
    //   display: none;
    // }

    .rsc-ts-image {
      object-fit: contain;
      height: 40px;
      width: 40px;
      background-color: $color_avatar_background;
    }

    .eval-root {
      position: relative;
    }

    .eval-bubble {
      background-color: #ffecbc;
      position: absolute;
      top: -12px;
      left: -12px;
      right: -12px;
      padding: 12px;
    }

    .rsc-os {
      padding: 16px;
      background-color: $color_gray;
      position: sticky;
      bottom: 0;
      z-index: 100;
    }

    .rsc-os-options {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      margin: 0;
      padding: 0;
    }

    .rsc-os-option {
      margin-inline-end: 4px;
    }

    .rsc-ts-user {
      margin-bottom: 30px;
      padding: 0 24px;
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 24px;
      }

      .rsc-ts-bubble {
        background-color: '#054e91';
      }
    }

    .rsc-cs {
      // justify-content: flex-end;
      // margin: 30px 0;
      // background-color: #f5f5f5;

      @include breakpoint-up(md) {
        padding: 20px;
      }

      .rsc-ts-bubble {
        background-color: '#054e91';
        font-size: 14px;
      }

      .rsc-loading {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        background-color: #f5f5f5;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
        display: flex;
        // margin: 0 6px 10px 6px;
        padding: 16px;
      }
    }

    .rsc-cs:first-child {
      margin-top: auto;
    }
  }
}
