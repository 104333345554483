export const LINKTYPES = {
  EXTERNAL: "EXTERNAL",
  ABSOLUTE: "ABSOLUTE",
  RELATIVE: "RELATIVE",
  PDF: "EXTERNAL",
  MAIL: "EXTERNAL",
  PHONE: "EXTERNAL",
};


export default {
  characters: {
    USER: 'USER',
    BOT: 'BOT',
    SYSTEM: 'SYSTEM',
    STAGEMANAGER: 'STAGEMANAGER',
  },
  drawers: {
    'facility': 'facility',
    'settings': 'settings',
  },
  ACTIVITY_TYPES: {
    'FAC_DETAIL': 'FAC_DETAIL',
    'FAC_EXT_WEBSITE': 'FAC_EXT_WEBSITE',
    'FAC_EXT_HOTLINE': 'FAC_EXT_HOTLINE',
    'FAC_EXT_WARMLINE': 'FAC_EXT_WARMLINE',
    'FAC_EXT_PHONE': 'FAC_EXT_PHONE',
    'FAC_EXT_SMS': 'FAC_EXT_SMS',
  },
}