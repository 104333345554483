import React, { useEffect, useContext } from 'react'
import { GeneralContext } from '@/provider/GeneralProvider'
import useUserInput from '@/core/hooks/useUserInput'
import _ from 'lodash'

const Com = ({ triggerNextStep, previousStep, steps }) => {
  const {
    chatBotData,
    onStageSetup,
    updateCurrentStep
    //
  } = useContext(GeneralContext)

  // collect user inputs for search
  useUserInput(previousStep)

  useEffect(() => {
    updateCurrentStep(previousStep)
    // Based on the result, decide which step to go to next
    let nextStepId = previousStep.action || previousStep.id

    let prevStepRawData = steps[previousStep.id]
    if (prevStepRawData?.metadata) {
      const { action } = prevStepRawData.metadata
      if (action) {
        nextStepId = action
      }
    }

    // Trigger the next step
    triggerNextStep({ trigger: nextStepId })
  }, [])

  // Return null since we don't want to render anything for this step
  return null
}

export default Com
